<!-- 默认档期跨度-->
<template>
  <div>
    <el-row style="margin-top: 10px">
      <el-col style="padding-left: 25px">
        <h3 style="display: inline-block">默认档期跨度设置</h3>
        <el-popover
            placement="right-start"
            width="300"
            trigger="hover">
          <template #default>
            <span style="font-size: 15px">
              <b>参数描述：</b>默认档期跨度是指添加婚纱档期时,婚纱在婚期前后自动保留的最大天数<br>
              <br>
              <b>参数范围：</b>最小值为0天，最大值为30天
            </span>
          </template>
          <i slot="reference" class="el-icon-question" style="font-size: 20px;color: #017ffd;margin-left: 10px"></i>
        </el-popover>
      </el-col>
    </el-row>
    <el-row style="margin-top: 15px;margin-bottom: 15px;">
      <el-col :span="20" :offset="1">
        <el-slider
            :max="30"
            v-model="rule"
            @change="change"
            show-input>
        </el-slider>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "schedule-rule",
  created() {
    this.queryDefaultScheduleRule()
  },
  data() {
    return {
      tenantCrop: localStorage.getItem("tenantCrop"),
      rule: null,
    }
  },
  methods: {
    queryDefaultScheduleRule() {
      this.$axios({
        method: "get",
        url: "/schedule/queryScheduleRule",
        params: {
          tenantCrop: this.tenantCrop
        }
      }).then(res => {
        this.rule = res.data.data.rule
      })
    },
    change() {
      this.$axios({
        method: "post",
        url: "/schedule/updateScheduleRule",
        params: {
          tenantCrop: this.tenantCrop,
          rule: this.rule,
        }
      }).then(res => {
        let flag = res.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" : res.data.msg,
          type: flag? 'success' :'error',
          duration: 500,
        })
        this.queryDefaultScheduleRule()
      })
    }
  },

}
</script>

<style scoped>

</style>